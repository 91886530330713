<template>
  <div class="contact-form flex">
    <form
      @submit.prevent="sendForm()"
      :style="containerStyle"
      :class="['contact-form-wrapper transparent', $props.color, $props.direction, $props.position]"
      ref="Wrapper"
    >
      <div :class="['contact-form-wrapper-colored flex', $props.color, $props.position]">
        <div ref="Form" class="contact-form-wrapper-colored-text flex-lg transparent">
          <h4 :class="['contact-form-wrapper-colored-text-title text-center', $props.color]">
            {{ $t("contact.form.title") }}
          </h4>
          <p class="contact-form-wrapper-colored-text-paragraph text-justify">
            {{ $t("contact.form.paragraph") }}
          </p>
          <div
            :class="[
              'contact-form-wrapper-colored-form flex',
              { loading: loadingPercentage !== 100 }
            ]"
          >
            <loading-mask
              :percentage="loadingPercentage"
              :backgroundColor="$utils.styles.colorsPalette.darkRaw"
              :backgroundOpacity="0.15"
              :sizeIsotype="50"
              :isAppMask="false"
            />
            <div class="form-container flex text-center">
              <div ref="termsAndConditions" class="form-container-description flex">
                <span class="form-container-description-text">
                  {{ $t("contact.form.label.privacyPolicyA") }}
                  <a
                    :href="$utils.getPageRoute('/aviso-de-privacidad/')"
                    :title="$t('navigation.privacyPolicy')"
                    class="general-anchor decorated"
                    target="_blank"
                  >
                    <span>{{ $t("navigation.privacyPolicy") }}</span>
                  </a>
                  {{ $t("contact.form.label.privacyPolicyB") }}
                  <a
                    :href="$utils.getPageRoute('/terminos-y-condiciones/')"
                    :title="$t('navigation.termsAndConditions')"
                    class="general-anchor decorated"
                    target="_blank"
                  >
                    <span>{{ $t("navigation.termsAndConditions") }}</span> </a
                  >.
                </span>
              </div>
              <input
                @change="validateInput($event.target.name)"
                v-model="form.termsAndConditions.value"
                v-wrong:wrong="form.termsAndConditions.invalid"
                type="checkbox"
                name="termsAndConditions"
                class="form-container-input checkbox margin-b-md"
              />
              <div class="form-container-description flex small text-left">
                <span class="form-container-description-text">{{
                  form.termsAndConditions.invalid
                }}</span>
              </div>
            </div>
            <div ref="firstname" class="form-container flex">
              <div class="form-container-description flex">
                <span class="form-container-description-text"
                  >{{ $t("contact.form.label.firstname") }}*</span
                >
              </div>
              <input
                @blur="validateInput($event.target.name)"
                v-model.trim="form.firstname.value"
                v-wrong:wrong="!!form.firstname.invalid"
                v-focus:focus
                ref="firstname"
                name="firstname"
                class="form-container-input margin-b-md"
                type="text"
                maxlength="250"
              />
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.firstname.invalid }}</span>
              </div>
            </div>
            <div ref="lastname" class="form-container flex">
              <div class="form-container-description flex">
                <span class="form-container-description-text"
                  >{{ $t("contact.form.label.lastname") }}*</span
                >
              </div>
              <input
                @blur="validateInput($event.target.name)"
                v-model.trim="form.lastname.value"
                v-wrong:wrong="!!form.lastname.invalid"
                v-focus:focus
                ref="lastname"
                name="lastname"
                class="form-container-input margin-b-md"
                type="text"
                maxlength="250"
              />
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.lastname.invalid }}</span>
              </div>
            </div>
            <div ref="email" class="form-container flex">
              <div class="form-container-description flex">
                <span class="form-container-description-text"
                  >{{ $t("contact.form.label.email") }}*</span
                >
              </div>
              <input
                @blur="validateInput($event.target.name)"
                v-model.trim="form.email.value"
                v-wrong:wrong="!!form.email.invalid"
                v-focus:focus
                ref="email"
                name="email"
                class="form-container-input margin-b-md"
                type="text"
                maxlength="250"
                placeholder="email@xyz.com"
              />
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.email.invalid }}</span>
              </div>
            </div>
            <div ref="phone" class="form-container flex">
              <div class="form-container-description flex">
                <span class="form-container-description-text">{{
                  $t("contact.form.label.phone")
                }}</span>
              </div>
              <input
                @blur="validateInput($event.target.name)"
                v-model.trim="form.phone.value"
                v-wrong:wrong="form.phone.invalid"
                v-mask="'## ## ## ## ##'"
                v-focus:focus
                ref="phone"
                name="phone"
                class="form-container-input margin-b-md"
                type="text"
                placeholder="## ## ## ## ##"
              />
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.phone.invalid }}</span>
              </div>
            </div>
            <div ref="company" class="form-container flex">
              <div class="form-container-description flex">
                <span class="form-container-description-text">{{
                  $t("contact.form.label.company")
                }}</span>
              </div>
              <input
                @blur="validateInput($event.target.name)"
                v-model.trim="form.company.value"
                v-wrong:wrong="form.company.invalid"
                v-focus:focus
                :placeholder="$t('contact.form.placeholder.company')"
                ref="company"
                name="company"
                class="form-container-input margin-b-md"
                type="text"
                maxlength="250"
              />
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.company.invalid }}</span>
              </div>
            </div>
            <div ref="description" class="form-container full flex">
              <div class="form-container-description flex">
                <span class="form-container-description-text"
                  >{{ $t("contact.form.label.description") }}*</span
                >
                <span class="form-container-description-text text-right">
                  ({{ form.description.value ? form.description.value.length : 0 }}/2000)
                </span>
              </div>
              <textarea
                @blur="validateInput($event.target.name)"
                v-model.trim="form.description.value"
                v-wrong:wrong="form.description.invalid"
                v-focus:focus
                :placeholder="$t('contact.form.placeholder.description')"
                ref="description"
                name="description"
                class="form-container-input textarea margin-b-md"
                maxlength="2000"
              ></textarea>
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.description.invalid }}</span>
              </div>
            </div>
            <div class="form-container contact-form-wrapper-colored-form-container flex">
              <div
                v-wrong:wrong="form.recaptcha.invalid"
                ref="recaptcha"
                class="contact-form-wrapper-colored-form-container-recaptcha"
              >
                <div v-wrong:wrong="form.recaptcha.invalid" id="recaptcha"></div>
              </div>
              <div class="form-container-description small flex">
                <span class="form-container-description-text">{{ form.recaptcha.invalid }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="['contact-form-wrapper-transparent flex', $props.position]">
        <button
          :disabled="invalidForm || loadingPercentage !== 100"
          :title="$t('contact.form.button')"
          class="contact-form-wrapper-transparent-cta general-cta tertiary text-center transparent"
          ref="CtaSendForm"
          type="submit"
        >
          <span>
            {{ $t("contact.form.button") }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { VueMaskDirective as mask } from "v-mask";
import focus from "@/directives/input/focus";
import wrong from "@/directives/input/wrong";

const LoadingMask = () =>
  import(/* webpackChunkName: "LoadingMaskComponent" */ "@/components/Global/LoadingMask.vue");

export default {
  name: "ContactForm",
  components: {
    LoadingMask
  },
  props: {
    /*
     * @property - color
     * Sets color style for background
     */
    color: {
      type: String,
      default: "primary"
    },
    /*
     * @property - direction
     * Sets direction style for background
     */
    direction: {
      type: String,
      default: "bottom"
    },
    /*
     * @property - position
     * Sets position style for background
     */
    position: {
      type: String,
      default: "left"
    }
  },
  directives: {
    focus,
    wrong,
    mask
  },
  data() {
    return {
      loadingPercentage: 100,
      geolocationId: null,
      form: {
        termsAndConditions: { invalid: null, value: false },
        firstname: { invalid: null, value: null },
        lastname: { invalid: null, value: null },
        email: { invalid: null, value: null },
        phone: { invalid: null, value: null },
        company: { invalid: null, value: null },
        description: { invalid: null, value: null },
        recaptcha: { invalid: null, value: null },
        latitude: { invalid: null, value: null },
        longitude: { invalid: null, value: null }
      },
      image: {
        active: 0,
        stock: [
          "/img/carousel/desktop-code.jpg",
          "/img/carousel/desktop-electronics.jpg",
          "/img/carousel/desktop-screens.jpg",
          "/img/carousel/desktop-tech-art.jpg",
          "/img/dataContainer/desktop-architectural-design.jpg",
          "/img/dataContainer/desktop-architectural-design-2.jpg",
          "/img/dataContainer/desktop-architectural-design-3.jpg",
          "/img/jumbotron/big-forest-tree.jpg"
        ]
      }
    };
  },
  computed: {
    /*
     * @computed - containerStyle
     * Returns current background image to use
     */
    containerStyle() {
      const { stock, active } = this.image;
      return { backgroundImage: `url(${stock[active]})` };
    },
    /*
     * @computed - directionStep
     * Returns current direction step according component direction
     */
    directionStep() {
      return this.$props.position === "left" ? 1 : -1;
    },
    /*
     * @computed - invalidForm
     * Used to disable submit button and avoid sendForm to be executed. Obtains a general valid state for the current form.
     */
    invalidForm() {
      return Object.keys(this.form).reduce((value, el) => {
        value += !!this.form[el].invalid;
        return !!value;
      }, false);
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls m_animateEntry to animate component according the configuration sended, to work in both mobile and desktop versions
     * @param - none
     */
    async animateEntry() {
      const { Wrapper, Form, CtaSendForm } = this.$refs;
      const { large } = this.$utils.styles.breakpoints;

      await this.m_animateFade(Wrapper, 1000, "in");
      this.m_animateEntry([
        {
          component: Wrapper,
          method: "animateComponent",
          operationBreakpoint: "LARGEROREQUAL",
          behavior: "top",
          boundary: large
        },
        {
          component: Form,
          method: "animateForm",
          operationBreakpoint: "SHORTER",
          behavior: "top",
          boundary: large
        },
        {
          component: CtaSendForm,
          method: "animateCtaSendForm",
          operationBreakpoint: "SHORTER",
          behavior: 50,
          boundary: large
        }
      ]);
    },
    /*
     * @function - animateComponent
     * Calls DataContainer elements fade and translate stepped animation
     * @param - none
     */
    animateComponent() {
      this.animateForm(0);
      this.animateCtaSendForm(250);
    },
    /*
     * @function - animateTitle
     * Calls Title element fade and translate stepped animation
     * @param - none
     */
    animateForm(animationDelay = 0) {
      const { Form } = this.$refs;

      this.m_animateTranslation(
        Form,
        250,
        this.directionStep * 100,
        0,
        0,
        0,
        animationDelay,
        false
      );
      this.m_animateFade(Form, 500, "in", animationDelay);
    },
    /*
     * @function - animateCtaSendForm
     * Calls CtaSendForm element fade and translate stepped animation
     * @param - none
     */
    animateCtaSendForm(animationDelay = 0) {
      const { CtaSendForm } = this.$refs;

      if (CtaSendForm) {
        this.m_animateTranslation(
          CtaSendForm,
          250,
          -this.directionStep * 100,
          0,
          0,
          0,
          animationDelay,
          false
        );
        this.m_animateFade(CtaSendForm, 500, "in", animationDelay);
      }
    },
    /*
     * @function - setActiveImage
     * Defines randomized active background image for the component, according to data stock
     * @param - none
     */
    setActiveImage() {
      this.image.active = Math.floor(Math.random() * this.image.stock.length);
    },
    /*
     * @function - setRecaptcha
     * Sets recaptcha client component with specific data
     * @param - none
     */
    setRecaptcha() {
      window.grecaptcha.render("recaptcha", {
        sitekey: this.$store.state.Global.env.RECAPTCHA_WEBSITE_KEY,
        theme: "dark",
        callback: response => {
          this.form.recaptcha.value = response;
          this.form.recaptcha.invalid = null;
        },
        "expired-callback": () => {
          this.form.recaptcha.value = null;
        },
        "error-callback": () => {
          this.form.recaptcha.value = null;
          this.form.recaptcha.invalid = this.$t("contact.messages.recaptchaConnectionError");
        }
      });
    },
    /*
     * @function - setGeolocation
     * Sets or clears geolocation coordinates
     * @param - add (Boolean): If true geolocation listener is set, otherwise it is cleared from memory
     */
    setGeolocation(add = true) {
      if (!("geolocation" in navigator)) {
        return;
      }

      if (add) {
        this.geolocationId = navigator.geolocation.watchPosition(({ coords }) => {
          this.form.latitude.value = coords.latitude;
          this.form.longitude.value = coords.longitude;
        });
      } else {
        navigator.geolocation.clearWatch(this.geolocationId);
        this.geolocationId = null;
      }
    },
    /*
     * @function - validateInput
     * Validate specific input value using $inputValidation plugin
     * @param - input (String): Defines the name of the input to validate
     */
    validateInput(input = null) {
      if (!input) {
        return;
      }

      const { invalid, validationValue } = this.$inputValidation.validateInput(
        this.form[input].value,
        input
      );

      if (invalid && typeof invalid === "string") {
        // Text to show
        this.form[input].invalid = this.$t(invalid, {
          value: validationValue,
          input: this.$t(`contact.form.label.${input}`)
        });
      } else {
        // Boolean true OR null
        this.form[input].invalid = invalid;
      }
    },
    /*
     * @function - sendForm
     * Method to submit form
     * @param - none
     */
    async sendForm() {
      if (this.invalidForm || this.loadingPercentage !== 100) {
        return;
      }
      const firstInputInvalid = Object.keys(this.form).reduce((lastValue, input) => {
        this.validateInput(input);

        // Get first input invalid
        return !lastValue && !firstInputInvalid && this.form[input].invalid ? input : lastValue;
      }, null);

      await this.$nextTick();

      if (this.invalidForm) {
        this.$bus.$emit("Messager: add text", {
          value: this.$t("contact.messages.formInvalid"),
          style: "danger"
        });

        // We can track the first invalid input, scroll to it
        if (firstInputInvalid && this.$refs[firstInputInvalid]) {
          // Gap depending of it is mobile or desktop
          const gap =
            this.$store.state.Global.window.width < this.$utils.styles.breakpoints.large
              ? -130
              : -120;
          this.$utils.scrollTo(this.$refs[firstInputInvalid], gap);
        }
      } else {
        this.loadingPercentage = 30;

        const body = {
          firstname: this.form.firstname.value,
          lastname: this.form.lastname.value,
          email: this.form.email.value,
          description: this.form.description.value,
          termsAndConditions: this.form.termsAndConditions.value,
          recaptcha: this.form.recaptcha.value,
          language: this.$i18n.locale
        };
        if (this.form.phone.value) {
          body.phone = this.form.phone.value;
        }
        if (this.form.company.value) {
          body.company = this.form.company.value;
        }
        if (this.form.latitude.value) {
          body.latitude = this.form.latitude.value;
        }
        if (this.form.longitude.value) {
          body.longitude = this.form.longitude.value;
        }

        try {
          const { data } = await this.$dependencies.neysla.contact.post({ body });
          setTimeout(this.resetForm, 400);
          console.log(`Contact - sendForm: ${data.message}`);

          this.$bus.$emit("Messager: add text", {
            value: this.$t("contact.messages.formValid"),
            style: "success"
          });
        } catch (error) {
          setTimeout(() => (this.loadingPercentage = 100), 400);
          console.error("Contact - sendForm: Something went wrong during posting message", error);

          if (error.status === 400) {
            Object.keys(error.data).forEach(input => {
              if (this.form[input]) {
                this.form[input].invalid = this.$t(error.data[input].invalid, {
                  value: error.data[input].validationValue,
                  input: this.$t(`contact.form.label.${input}`)
                });
              }
            });
            if (error.data && error.data.recaptcha) {
              window.grecaptcha.reset();
            }

            this.$bus.$emit("Messager: add text", {
              value: this.$t("contact.messages.formInvalid"),
              style: "danger"
            });
          } else {
            this.$bus.$emit("Messager: add text", {
              value: this.$t("contact.messages.formUnknownError"),
              style: "danger"
            });
          }
        }
      }
    },
    /*
     * @function - resetForm
     * Resets all form inputs data to default values
     * @param - none
     */
    resetForm() {
      this.loadingPercentage = 100;
      this.form.firstname.value = null;
      this.form.lastname.value = null;
      this.form.email.value = null;
      this.form.phone.value = null;
      this.form.company.value = null;
      this.form.description.value = null;
      this.form.termsAndConditions.value = false;
      this.form.recaptcha.value = null;
      window.grecaptcha.reset();
    }
  },
  created() {
    this.setActiveImage();
    this.setGeolocation();
  },
  mounted() {
    this.$dependencies.recaptcha.init(this.setRecaptcha, this.$i18n.locale);
  },
  beforeDestroy() {
    this.setGeolocation(false);
  }
};
</script>

<style lang="scss" scoped>
.contact-form {
  // overflow: hidden;

  &-wrapper {
    @include boxShadow(0.65, true, false);
    background: transparent no-repeat fixed;
    background-position: center;
    background-size: cover;
    display: inherit;
    flex-wrap: wrap;
    height: 134rem;
    position: relative;
    width: 100%;
    z-index: 0;

    @include breakpoint($xsmall) {
      height: 123rem;
    }

    @include breakpoint($small) {
      height: 107rem;
    }

    @include breakpoint($medium) {
      height: 91rem;
    }

    @include breakpoint($large) {
      height: 93rem;
    }

    @include breakpoint($xlarge) {
      height: 72rem;
    }

    &::before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &.primary.bottom.left::before {
      background-image: linear-gradient(175deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($main-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.primary.top.left::before {
      background-image: linear-gradient(185deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(95deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(97.5deg, rgba($main-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.primary.doubled.left::before {
      background-image: linear-gradient(175deg, rgba($main-blue, 0.75) 65%, transparent 35%),
        linear-gradient(185deg, rgba($main-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-blue, 0.75) 55%, transparent 45%),
          linear-gradient(95deg, rgba($main-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(97.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.primary.bottom.right::before {
      background-image: linear-gradient(5deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -82.5deg,
          rgba($main-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.primary.top.right::before {
      background-image: linear-gradient(-5deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-95deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -97.5deg,
          rgba($main-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.primary.doubled.right::before {
      background-image: linear-gradient(5deg, rgba($main-blue, 0.75) 65%, transparent 35%),
        linear-gradient(-5deg, rgba($main-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-blue, 0.75) 55%, transparent 45%),
          linear-gradient(-95deg, rgba($main-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(-82.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(-97.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.secondary.bottom.left::before {
      background-image: linear-gradient(175deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          82.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.top.left::before {
      background-image: linear-gradient(185deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(95deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          97.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.doubled.left::before {
      background-image: linear-gradient(175deg, rgba($main-green, 0.75) 65%, transparent 35%),
        linear-gradient(185deg, rgba($main-green, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-green, 0.75) 55%, transparent 45%),
          linear-gradient(95deg, rgba($main-green, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($main-green, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(97.5deg, rgba($main-green, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.secondary.bottom.right::before {
      background-image: linear-gradient(5deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -82.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.top.right::before {
      background-image: linear-gradient(-5deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-95deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -97.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.doubled.right::before {
      background-image: linear-gradient(5deg, rgba($main-green, 0.75) 65%, transparent 35%),
        linear-gradient(-5deg, rgba($main-green, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-green, 0.75) 55%, transparent 45%),
          linear-gradient(-95deg, rgba($main-green, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
            -82.5deg,
            rgba($main-green, 0.75) 67.5%,
            transparent 32.5%
          ),
          linear-gradient(-97.5deg, rgba($main-green, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.bottom.left::before {
      background-image: linear-gradient(175deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($live-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.top.left::before {
      background-image: linear-gradient(185deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(95deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(97.5deg, rgba($live-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.doubled.left::before {
      background-image: linear-gradient(175deg, rgba($live-blue, 0.75) 65%, transparent 35%),
        linear-gradient(185deg, rgba($live-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($live-blue, 0.75) 55%, transparent 45%),
          linear-gradient(95deg, rgba($live-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(97.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.bottom.right::before {
      background-image: linear-gradient(5deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -82.5deg,
          rgba($live-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.tertiary.top.right::before {
      background-image: linear-gradient(-5deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-95deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -97.5deg,
          rgba($live-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.tertiary.doubled.right::before {
      background-image: linear-gradient(5deg, rgba($live-blue, 0.75) 65%, transparent 35%),
        linear-gradient(-5deg, rgba($live-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($live-blue, 0.75) 55%, transparent 45%),
          linear-gradient(-95deg, rgba($live-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(-82.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(-97.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &-colored {
      flex: 1 0 100%;
      height: 65%;
      overflow: auto hidden;

      @include breakpoint($xsmall) {
        overflow: unset;
      }

      @include breakpoint($large) {
        flex: 1 0;
        height: 100%;
      }

      @include breakpoint($xlarge) {
        flex: 1 0 65%;
      }

      &.right {
        order: 2;
      }

      &.primary {
        color: $white-gray;
      }

      &-text {
        margin: auto 0.75rem;
        overflow: auto hidden;
        text-shadow: 0.125rem 0.125rem rgba($white-gray, 0.2);

        @include breakpoint($xsmall) {
          overflow: unset;
        }

        @include breakpoint($medium) {
          margin: auto 1rem;
        }

        @include breakpoint($large) {
          flex-direction: column;
          margin: auto 3rem;
        }

        &-title {
          border-bottom: 0.05rem solid $dark-gray;
          font-size: 1.75rem;

          @include breakpoint($small) {
            font-size: 2rem;
          }

          @include breakpoint($medium) {
            font-size: 2.25rem;
          }

          @include breakpoint($xlarge) {
            font-size: 2.75rem;
          }

          &.primary {
            border-bottom-color: $white-gray;
          }
        }

        &-paragraph {
          @include breakpoint($small) {
            font-size: 1.25rem;
          }

          @include breakpoint($xlarge) {
            font-size: 1.5rem;
          }
        }
      }

      &-form {
        border-radius: 0.5rem;
        flex-wrap: wrap;
        margin-bottom: 3.5rem;
        position: relative;
        transition: box-shadow 0.075s ease;

        &.loading {
          @include boxShadow(0.35, true, false);
        }

        &-container {
          overflow: auto hidden;

          @include breakpoint($xsmall) {
            overflow: unset;
          }

          &-recaptcha {
            margin: 1rem auto 0 auto;

            &.wrong {
              border-left: 0.4rem solid $live-red;
            }
          }
        }
      }
    }

    &-transparent {
      flex: 1 0 100%;
      height: 35%;

      @include breakpoint($large) {
        flex: 1 0;
        height: 100%;
      }

      @include breakpoint($xlarge) {
        flex: 1 0 35%;
      }

      &.right {
        order: 1;
      }

      &-cta {
        cursor: pointer;
        margin: auto;
        margin-top: 5rem;

        @include breakpoint($large) {
          margin-top: auto;
          position: sticky !important;
          top: 35vh;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
