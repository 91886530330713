let onFocus = null;
let onBlur = null;

export default {
  inserted(el, { arg }) {
    if (!el || !arg) {
      return;
    }

    onFocus = ({ target: { parentElement } }) => {
      if (parentElement && parentElement.classList) {
        parentElement.classList.add(arg);
      }
    };
    onBlur = ({ target: { parentElement } }) => {
      if (parentElement && parentElement.classList) {
        parentElement.classList.remove(arg);
      }
    };

    el.addEventListener("focus", onFocus);
    el.addEventListener("blur", onBlur);
  },
  unbind(el, { arg }) {
    if (!el || !arg) {
      return;
    }

    el.removeEventListener("focus", onFocus);
    el.removeEventListener("blur", onBlur);
  }
};
