<template>
  <div class="messager">
    <div
      v-for="text in textes"
      :key="text.key"
      :class="['messager-message', text.style, { visible: text.visible }]"
    >
      <div v-if="!text.avoidClose" class="text-right">
        <button
          @click="removeText(text.key)"
          :class="['messager-message-action', text.style]"
          type="button"
          name="button"
        >
          <font-awesome-icon :icon="['fa', 'times']" />
        </button>
      </div>
      <div class="text-center">
        <span>{{ text.value }}</span>
      </div>
      <div v-if="text.action && text.action.fn" class="text-center">
        <button
          @click="text.action.fn"
          :class="['messager-message-action', text.style]"
          type="button"
          name="button"
        >
          <font-awesome-icon v-if="!!text.action.icon" :icon="['fa', text.action.icon]" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Messager",
  data() {
    return {
      textes: []
    };
  },
  methods: {
    /*
     * @function - addText
     * Adds text object to be shown in the messager, and sets setTimeout to remove it
     * @param - text (Object): Defines the name of the input to validate
     */
    addText(text = {}) {
      if (!text.value) {
        return;
      }
      if (!text.style) {
        text.style = "info";
      }
      if (!text.timeout) {
        text.timeout = 6000;
      }
      if (!text.avoidClose) {
        text.avoidClose = false;
      }
      if (!text.key) {
        text.key = this.makeKey();
      }

      this.textes.push({ ...text, visible: false });
      setTimeout(() => {
        const i = this.textes.findIndex(val => val.key === text.key);

        if (i !== -1) {
          this.textes[i].visible = true;
        }
      }, 100);

      if (text.timeout !== "infinite") {
        setTimeout(() => this.removeText(text.key), text.timeout);
      }
    },
    /*
     * @function - makeKey
     * Generates unique ID key to be added on each text
     * @param - length (Number): Length of key to be created
     */
    makeKey(length = 30) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let result = "";

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    /*
     * @function - removeText
     * Removes text of specific ID key
     * @param - key (String): Key to be used to identify which text will be removed
     */
    removeText(key) {
      const i = this.textes.findIndex(text => text.key === key);
      if (i === -1) {
        return;
      }

      this.textes[i].visible = false;
      setTimeout(() => this.textes.splice(i, 1), 500);
    }
  },
  created() {
    this.$bus.$on("Messager: add text", this.addText);
    this.$bus.$on("Messager: remove text", this.removeText);
  },
  beforeDestroy() {
    this.$bus.$off("Messager: add text", this.addText);
    this.$bus.$off("Messager: remove text", this.removeText);
  }
};
</script>

<style lang="scss" scoped>
.messager {
  bottom: auto;
  left: 0;
  margin: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 910;

  @include breakpoint($small) {
    bottom: 0;
    left: auto;
    top: auto;
  }

  &-message {
    @include boxShadow(0.55, true, false);
    border-radius: 0.2rem;
    margin: 0 0.5rem;
    max-height: 0;
    opacity: 0;
    padding: 0.75rem 0.5rem;
    transition: max-height ease-out 0.5s, opacity linear 0.5s;

    @include breakpoint($small) {
      width: 25rem;
    }

    @include breakpoint($medium) {
      width: 28rem;
    }

    &.visible {
      margin: 0.5rem;
      max-height: 20rem;
      opacity: 1;
    }

    &.info {
      background-color: $main-blue;
      color: $white-gray;
    }

    &.danger {
      background-color: $live-red;
      color: $white-gray;
    }

    &.warning {
      background-color: $dark-purple;
      color: $white-gray;
    }

    &.success {
      background-color: $main-green;
      color: $dark-gray;
    }

    &-action {
      background-color: transparent;
      border: none;
      border-radius: 0.5rem;
      transition: background-color ease 0.35s, color ease 0.35s;

      &.info {
        color: $white-gray;

        &:hover,
        &:active {
          background-color: $white-gray;
          color: $main-blue;
        }
      }

      &.danger {
        color: $white-gray;

        &:hover,
        &:active {
          background-color: $white-gray;
          color: $live-red;
        }
      }

      &.warning {
        color: $white-gray;

        &:hover,
        &:active {
          background-color: $white-gray;
          color: $dark-purple;
        }
      }

      &.success {
        color: $dark-gray;

        &:hover,
        &:active {
          background-color: $white-gray;
          color: $main-green;
        }
      }
    }
  }
}
</style>
