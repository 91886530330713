export default {
  inserted({ parentElement }, { arg, value }) {
    if (!parentElement || !parentElement.classList || !arg) {
      return;
    }

    parentElement.classList[value ? "add" : "remove"](arg);
  },
  update({ parentElement }, { arg, value }) {
    if (!parentElement || !parentElement.classList || !arg) {
      return;
    }

    parentElement.classList[value ? "add" : "remove"](arg);
  }
};
