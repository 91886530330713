<template>
  <div>
    <contact-form color="tertiary" direction="doubled" position="left" />
  </div>
</template>

<script>
const ContactForm = () =>
  import(/* webpackChunkName: "ContactPage" */ "@/components/Contact/ContactForm.vue");

export default {
  name: "Contact",
  components: {
    ContactForm
  },
  computed: {
    /*
     * @computed - screenIsLargeOrLarger
     * TRUE if screen is large or larger width
     */
    screenIsLargeOrLarger() {
      return this.$store.state.Global.window.width >= this.$utils.styles.breakpoints.large;
    }
  }
};
</script>
